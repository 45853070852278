import { memo } from "react";
// next

import { Box } from "@mui/material";
import SvgIconStyle from "./SvgIconStyle";
import LogoIcon from "assets/icons/logo.svg";

// ----------------------------------------------------------------------

function Logo({ onDark = false, isSimple = false, sx }) {
  return (
    <Box>
      <Box
        sx={{
          width: 75,
          lineHeight: 0,
          cursor: "pointer",
          display: "inline-flex",
          ...sx,
        }}
        onClick={() => {
          window.location.href = `${
            window.location.origin
          }?code=${localStorage.getItem("Kiosk_ID")}`;
        }}
      >
        <SvgIconStyle
          src={LogoIcon}
          origin
          sx={{ width: 220, minWidth: 220, maxWidth: 220, height: 50 }}
        />
      </Box>
    </Box>
  );
}

export default memo(Logo);
