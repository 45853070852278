import React from "react";
//mui
import {
    Box,
    Typography,
    Stack,
    Grid,
    Button,
    CircularProgress,
    FormControl,
    Select,
    MenuItem,
} from "@mui/material";
//components
import {
    SvgIconStyle,
    CommonCounter,
    CommonInput,
    CommonModal,
} from "components";
import {EmailInputStyle, EmailSelectInputStyle} from "utils/uiData";
import {fDate} from "utils/formatTime";

import ArrowIcon from "assets/icons/arrow.svg";
import CartArrowIcon from "assets/icons/prev.svg";
import CancelIcon from "assets/icons/cancel.svg";
import RemoveIcon from "assets/icons/remove.svg";
import MenuIcon from "assets/icons/menu.svg";
import SymbolIcon from "assets/icons/symbol.svg";
import CartIcon from "assets/icons/cart.svg";
import {rmBr} from "../utils/string.utils";

// import { useTranslation } from "next-i18next";

export function TabPanel(props) {
    const {children, value, index, ...other} = props;
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`item-tab-${index}`}
            {...other}
        >
            {value === index && children}
        </div>
    );
}

export function ItemCard({item, ...other}) {
    return (
        <Box sx={{pointer: "cursor", px: 1}} {...other}>
            {item?.thumbnail ? (
                <Box
                    sx={{
                        backgroundColor: "grey.400",
                        width: "100%",
                        minWidth: 220,
                        paddingBottom: "75%",
                        borderRadius: "4px",
                        background: `center/cover no-repeat url(${item?.thumbnail})`,
                    }}
                />
            ) : (
                <Box
                    sx={{
                        backgroundColor: "grey.400",
                        width: "100%",
                        minWidth: 220,
                        paddingBottom: "75%",
                        borderRadius: "4px",
                        background: `center/30% no-repeat #ededed url(${SymbolIcon.src})`,
                        opacity: 0.6,
                    }}
                ></Box>
            )}

            <Typography fontSize={18} fontWeight={700} mt={1.5} mb={1} lineHeight={1}>
                {item?.name}
            </Typography>
            <Typography fontSize={14} color="grey.middle" lineHeight={1} mb={1}>
                {item?.business?.name}
            </Typography>
            <Typography
                fontSize={20}
                fontWeight={700}
                color="primary.main"
                lineHeight={1}
            >
                {Number(item?.price).toLocaleString()}원
            </Typography>
        </Box>
    );
}

export function ItemArrow({prev, onClick}) {
    return (
        <Box
            sx={{
                display: "block",
                position: "absolute",
                bottom: "-10%",
                left: prev ? "calc(20% + 45px)" : "",
                right: prev ? "" : "calc(20% - 45px)",
                transform: "translate(-50%,0)",
                zIndex: "99",
            }}
        >
            <Button
                variant="outlined"
                sx={{
                    color: "grey.300",
                    borderColor: "grey.300",
                    borderRadius: "4px",
                    py: 0,
                    px: 1.2,
                    alignItems: "center",
                    "&:hover": {
                        borderColor: "grey.300",
                    },
                }}
                onClick={onClick}
            >
                {prev ? (
                    <SvgIconStyle src={ArrowIcon} size={14} sx={{mr: 1.5}}/>
                ) : null}
                <Typography fontSize={20}>{prev ? "이전" : "다음"}</Typography>
                {prev ? null : (
                    <SvgIconStyle
                        src={ArrowIcon}
                        size={14}
                        sx={{ml: 1.5, transform: "rotate(180deg)"}}
                    />
                )}
            </Button>
        </Box>
    );
}

export function CartArrow({prev, onClick}) {
    return (
        <Box
            sx={{
                display: "block",
                position: "absolute",
                bottom: "20%",
                left: prev ? -50 : "",
                right: prev ? "" : 0,
                minHeight: "80px",
                zIndex: "99",
            }}
        >
            <Button sx={{p: 0}} onClick={onClick}>
                {prev ? (
                    <SvgIconStyle origin src={CartArrowIcon} size={50} sx={{mr: 1.5}}/>
                ) : null}
                {prev ? null : (
                    <SvgIconStyle
                        origin
                        src={CartArrowIcon}
                        size={50}
                        sx={{ml: 1.5, transform: "rotate(180deg)"}}
                    />
                )}
            </Button>
        </Box>
    );
}

export function BottomButtons({left, right}) {
    const arr = [{...left}, {...right}].filter((item) => {
        return Object.keys(item).length;
    });
    return (
        <Stack direction="row">
            {arr.map((item, i) => (
                <Button
                    key={i}
                    sx={{
                        borderRadius: 0,
                        height: 80,
                        display: "flex",
                        alignItems: "center",
                        "&:hover": {
                            backgroundColor: i / 2 == 0 ? "grey.100" : "primary.main",
                        },
                    }}
                    variant="contained"
                    color={i / 2 == 0 ? "secondary" : "primary"}
                    fullWidth
                    onClick={item.onClick}
                    disabled={item.disabled}
                >
                    {item.icon ? (
                        <SvgIconStyle src={item.icon} size={32} sx={{mr: 1}}/>
                    ) : null}
                    <Typography fontSize={28} fontWeight={700}>
                        {" "}
                        {item.label}
                    </Typography>
                </Button>
            ))}
        </Stack>
    );
}

export function CartCard({item, onClick, t, ...other}) {
    return (
        // <Grid
        //   item
        //   sx={{
        //     backgroundColor: "white",
        //     borderRadius: "4px",
        //     p: 3,
        //     minWidth: "225px",
        //     maxWidth: "225px",
        //     position: "relative",
        //     display: "flex",
        //     flexDirection: "column",
        //     justifyContent: "space-between",
        //   }}
        //   {...other}
        // >
        <Box
            {...other}
            sx={{
                backgroundColor: "white",
                borderRadius: "4px",
                p: 3,
                position: "relative",
                display: "flex !important",
                flexDirection: "column !important",
                justifyContent: "space-between",
                minHeight: "170px",
            }}
        >
            <Button
                onClick={onClick}
                sx={{position: "absolute", top: 12, right: 8}}
            >
                <SvgIconStyle src={CancelIcon} size={14} sx={{color: "grey.300"}}/>
            </Button>

            <Box>
                <Typography
                    fontSize={18}
                    fontWeight={700}
                    lineHeight={1}
                    mb={1.5}
                    mr={2.5}
                >
                    {item.menuName}
                </Typography>

                {item.menuType && (
                    <Typography color="grey.200" lineHeight={1} mb={1.5} mr={2.5}>
                        {item.menuType}
                    </Typography>
                )}
                {!item.menuType && item.description && (
                    <Typography color="grey.200" lineHeight={1} mb={1.5} mr={2.5}>
                        {item.description.split("-")[1]}
                    </Typography>
                )}
                {/* {item.description && (
          <Typography color="grey.200" lineHeight={1} mb={1.5} mr={2.5}>
            {item.description.split("-")[1]}
          </Typography>
        )} */}
                {item.mode === "TIME" && (
                    <Typography color="grey.200" lineHeight={1} mb={1} mr={2.5}>
                        {t("receive-time")} : {fDate(item.orderTime, "HH:mm")}
                    </Typography>
                )}
            </Box>

            <Stack direction="row" alignItems="center" justifyContent="space-between">
                {/* <Typography>{item.buyCount}개</Typography> */}
                <Typography>{t("unit", {count: item.buyCount})}</Typography>
                <Typography
                    fontSize={18}
                    fontWeight={700}
                    color="primary.main"
                    lineHeight={1}
                    mr={1}
                >
                    {/* {Number(item.paymentPrice).toLocaleString()}원 */}
                    {t("price", {price: Number(item.paymentPrice).toLocaleString()})}
                </Typography>
            </Stack>
        </Box>
        // </Grid>
    );
}

export function OrderCard({item, removeItem, ...other}) {
    return (
        <Grid
            container
            sx={{
                pointer: "cursor",
                py: 3,
                mt: 1,
                alignItems: "start",
                borderBottom: "1px solid",
                borderColor: "divider",
            }}
            {...other}
        >
            <Grid
                item
                xs={3}
                sx={{backgroundColor: item.image ? "transparent" : "grey.600"}}
            >
                <Box
                    sx={{
                        backgroundColor: "grey.400",
                        width: "100%",
                        height: "150px",
                        borderRadius: "4px",
                        background: `center/contain no-repeat url(${item.image})`,
                    }}
                />
            </Grid>
            <Grid item xs={9}>
                <Box
                    sx={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "space-between",
                        height: "100%",
                        ml: 2,
                    }}
                >
                    <Box>
                        <Stack
                            direction="row"
                            alignItems="center"
                            justifyContent="space-between"
                        >
                            <Typography fontSize={14} color="grey.middle" lineHeight={1}>
                                {item?.businessName || ""}
                            </Typography>
                            <Button sx={{p: 0}} onClick={() => removeItem(item)}>
                                <SvgIconStyle src={RemoveIcon} size={27} origin/>
                            </Button>
                        </Stack>

                        <Typography fontSize={20} fontWeight={700}>
                            {rmBr(item?.branchName|| "")}
                        </Typography>

                        <Typography color="grey.200" fontSize={18} fontWeight={500}>
                            {item?.description || ""}
                        </Typography>
                    </Box>

                    <Stack
                        direction="row"
                        justifyContent="space-between"
                        alignItems="end"
                    >
                        <Box>
                            <Typography fontSize={18} fontWeight={500}>
                                총 수량
                            </Typography>

                            <CommonCounter
                                isCart
                                max={
                                    !!item.salesLimitOnce
                                        ? Math.min(...[item.qty, item.salesLimitOnce])
                                        : item.qty
                                }
                                count={item?.buyCount || 0}
                                item={item}
                            />
                        </Box>

                        <Typography
                            fontSize={24}
                            fontWeight={700}
                            color="primary.main"
                            lineHeight={1}
                        >
                            {Number(item?.paymentPrice || 0).toLocaleString()}원
                        </Typography>
                    </Stack>
                </Box>
            </Grid>
        </Grid>
    );
}

export function EmailInput({
                               onSelect,
                               errors,
                               sx,
                               selected,

                               emailValue,
                               onEmailTypeChange,
                               onClick,
                               ...other
                           }) {
    const Email_List = [
        {label: "direct input", value: "write"},
        {label: "naver.com", value: "naver.com"},
        {label: "nate.com", value: "nate.com"},
        {label: "daum.net", value: "daum.net"},
        {label: "gmail.com", value: "gmail.com"},
        {label: "kakao.com", value: "kakao.com"},
    ];

    return (
        <Grid container sx={{sx}}>
            <Grid item xs={6}>
                <CommonInput
                    id="email"
                    placeholder={"queenssmile"}
                    errors={errors}
                    onClick={() => onClick("email")}
                    {...other}
                />
            </Grid>

            <Grid
                item
                xs={1}
                sx={{display: "flex", justifyContent: "center", alignItems: "center"}}
            >
                <Typography sx={{mx: 1.5, mb: 2.5}}>@</Typography>
            </Grid>

            <Grid item xs={5} sx={{maxHeight: "48px"}}>
                <Box sx={{position: "relative"}}>
                    <FormControl sx={{width: "100%"}}>
                        <Select
                            fullWidth
                            onChange={onSelect}
                            defaultValue="write"
                            sx={{
                                ...EmailInputStyle,
                            }}
                            renderValue={(value) => (value === "write" ? null : value)}
                        >
                            {Email_List.map((item, i) => (
                                <MenuItem
                                    key={i}
                                    value={item.value}
                                    className="email-select-item"
                                >
                                    {item.label}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    {selected === "write" ? (
                        <CommonInput
                            id="emailType"
                            value={emailValue}
                            onChange={onEmailTypeChange}
                            placeholder={"email address"}
                            onClick={() => onClick("type")}
                            sx={{
                                ...EmailSelectInputStyle,
                            }}
                        />
                    ) : null}
                </Box>
            </Grid>
        </Grid>
    );
}

export function ConfirmMobile({
                                  open,
                                  onSubmit,
                                  onCancel,
                                  content,
                                  foreigner,
                                  ...other
                              }) {
    return (
        <CommonModal
            open={open}
            footer={{label: "확인", onClick: onSubmit}}
            subFooter={{label: "재입력", onClick: onCancel}}
            {...other}
        >
            <Box sx={{textAlign: "center", py: 2}}>
                <Typography variant="h3" fontSize={24} mb={2}>
                    {foreigner ? "이메일 확인" : "전화번호 확인"}
                </Typography>
                <Typography fontSize={20} color="grey.middle">
                    결제 완료 후, 입력하신 번호로 주문 내역이 <br/>
                    전송되오니 반드시 확인해주세요.
                </Typography>
                <Typography fontSize={34} mb={2}>
                    {foreigner
                        ? content
                        : content.replace(/^(\d{2,3})(\d{3,4})(\d{4})$/, `$1-$2-$3`)}
                </Typography>
            </Box>
        </CommonModal>
    );
}

// export function SelectPayMethod({ onClick }) {
//   return (
//     <Grid container spacing={2} mt={3}>
//       {[
//         { label: "카드결제", key: "card" },
//         {
//           label: "모바일페이",
//           key: "pay",
//           sub: "삼성페이 / 애플페이 / QR결제",
//         },
//       ].map((item, i) => (
//         <Grid item key={i} xs={6}>
//           <Button
//             sx={{
//               width: "100%",
//               border: "1px solid",
//               borderColor: "grey.300",
//               borderRadius: 1,
//               minHeight: "200px",
//               display: "flex",
//               justifyContent: "center",
//               alignItems: "center",
//               color: "black",
//             }}
//             onClick={() => onClick(item)}
//           >
//             <Box sx={{ textAlign: "center" }}>
//               <Typography variant="h2" fontSize={30}>
//                 {item.label}
//               </Typography>
//               {item.sub && (
//                 <Typography color="grey.middle" fontSize={24}>
//                   {item.sub}
//                 </Typography>
//               )}
//             </Box>
//           </Button>
//         </Grid>
//       ))}
//     </Grid>
//   );
// }

export function GoListButton({onClick, t}) {
    return (
        <Box sx={{textAlign: "center"}}>
            <Button
                // onClick={() => router.push("/items/list")}
                onClick={onClick}
                variant="outlined"
                sx={{
                    alignItems: "center",
                    color: "black",
                    borderColor: "grey.400",
                    px: 4,
                    my: 5,
                }}
            >
                <SvgIconStyle src={MenuIcon} size={20} sx={{mr: 1}}/>
                <Typography fontSize={20} fontWeight={700}>
                    {/* 목록보기 */}
                    {t("menu")}
                </Typography>
            </Button>
        </Box>
    );
}

export function AddCartButton({onClick, disabled, t}) {
    return (
        <Box sx={{textAlign: "end", mt: 5}}>
            <Button
                disabled={disabled}
                variant="contained"
                sx={{
                    py: 2,
                    px: 7,
                    fontSize: 20,
                    "&:active,&:hover": {backgroundColor: "primary.main"},
                }}
                onClick={onClick}
            >
                <SvgIconStyle src={CartIcon} sx={{mr: 2}}/>
                {/* 장바구니 담기 */}
                {t("add-cart")}
            </Button>
        </Box>
    );
}

export function FastestButton({onClick}) {
    return (
        <Box>
            <Button
                variant="contained"
                sx={{
                    py: 1,
                    fontSize: 16,
                    "&:hover,&:active": {backgroundColor: "primary.main"},
                }}
                // onClick={() => setOpen("fastest")}
                onClick={onClick}
            >
                가장 빠른 시간 구매
            </Button>
        </Box>
    );
}

export function Loading({size, circleSize, sx}) {
    return (
        <Stack
            direction="column"
            justifyContent="center"
            alignItems="center"
            sx={{
                height: "100%",
                position: "relative",

                ...sx,
            }}
        >
            <SvgIconStyle
                src={SymbolIcon}
                origin
                sx={{
                    width: size ? size : "40px",
                    height: size ? size : "40px",
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%,-50%)",
                }}
            />
            <CircularProgress size={circleSize ? circleSize : 80} thickness={2}/>
        </Stack>
    );
}
