import { useState, useRef, useEffect } from "react";
import { useNavigate } from "react-router-dom";
// components
import Layout from "layout/index";
import { SvgIconStyle, CommonInput } from "components";
import { Container, Typography, Box, Button, Stack } from "@mui/material";
import {
  BottomButtons,
  OrderCard,
  EmailInput,
  ConfirmMobile,
} from "components/unitComponent";
import { keyboardLayout } from "utils/uiData";

// translations

import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import BackIcon from "assets/icons/back.svg";

import useCart from "../store";

import Keyboard from "react-simple-keyboard";
import "react-simple-keyboard/build/css/index.css";
import Api from "utils/wApi/index";

// ----------------------------------------------------------------------

export default function Order() {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    setValue,
    formState: { isValid, errors },
  } = useForm({ mode: "onChange", criteriaMode: "all" });

  const [open, setOpen] = useState(null);
  const [foreigner, setForeigner] = useState(false);
  const [selectedEmail, setSelectedEmail] = useState("write");

  const [input, setInput] = useState("");
  const [emailInput, setEmailInput] = useState("");
  const [emailTypeInput, setEmailTypeInput] = useState("");

  const [focused, setFocused] = useState("phone");

  const [layout, setLayout] = useState("numeric");
  const keyboard = useRef();
  const numKeyboard = useRef();

  const { cart, total, callCart } = useCart((state) => state);

  const emailContent = () => {
    return selectedEmail === "write"
      ? `${emailInput}@${emailTypeInput}`
      : `${emailInput}@${selectedEmail}`;
  };

  const onChange = (value) => {
    switch (focused) {
      case "phone":
        setInput(value);
        break;
      case "email":
        setEmailInput(value);
        break;
      case "type":
        setEmailTypeInput(value);
        break;
      default:
        break;
    }
  };

  // const onChangeInput = (event) => {
  // const input = event.target.value;
  // setInput(input);
  // keyboard.current.setInput(input);
  // };

  // const onEmailChange = (event) => {
  //   // console.log("emailchange", event);
  //   // const input = event.target.value;
  //   // setEmailInput(input);
  //   // keyboard.current.setEmailInput(input);
  // };

  // const onEmailTypeChange = (event) => {
  //   const input = event.target.value;
  //   // setEmailTypeInput(input);
  //   // keyboard.current.setEmailTypeInput(input);
  // };

  const handleShift = () => {
    const newLayoutName = layout === "default" ? "shift" : "default";
    setLayout(newLayoutName);
  };

  const onKeyPress = (button) => {
    if (button === "{shift}" || button === "{lock}") handleShift();
  };

  useEffect(() => {
    if (!keyboard.current) return;
    if (foreigner) {
      setLayout(foreigner ? "default" : "numeric");
    }
  }, [foreigner]);

  useEffect(() => {
    if (!keyboard.current) return;
    keyboard.current.clearInput();
    switch (focused) {
      case "phone":
        keyboard.current.setInput(input);
        break;
      case "email":
        keyboard.current.setInput(emailInput);
        break;
      case "type":
        keyboard.current.setInput(emailTypeInput);
        break;
      default:
        break;
    }
  }, [foreigner, focused]);

  useEffect(() => {
    callCart();
  }, []);

  async function onSubmit() {
    const body = {
      idCartMenuTimes: cart.map((item) => item.id), //상품 id 값
      amount: total, //가격
      mobile: foreigner ? undefined : input, //모바일
      email: foreigner ? emailContent() : undefined, //이메일
    };

    try {
      const { data } = await Api.payment(body);
      // alert(data.orderId);
      navigate("/pay", { state: { orderId: data.orderId } });
    } catch (error) {
      alert("get OrderId error::", error.message);
    }
  }

  const gap = "56px";

  return (
    <Layout>
      <Container
        sx={{
          px: "0 !important",
          height: "100%",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Container
          sx={{
            flexGrow: 1,
            display: "flex",
            flexDirection: "column",
            position: "relative",
            pl: `${gap} !important`,
            pr: "0 !important",
            overflow: "scroll",
            "&:-webkit-scrollbar": { display: "none" },
          }}
        >
          <Button
            sx={{
              position: "absolute",
              left: 10,
              top: 10,
            }}
            onClick={() => navigate("/")}
          >
            <SvgIconStyle
              src={BackIcon.src}
              sx={{ color: "black", width: 10, height: 20 }}
            />
          </Button>

          <Box
            sx={{
              width: "100%",
              flexGrow: 1,
              display: "flex",
              flexDirection: "column",
              "&:-webkit-scrollbar": { display: "none" },
            }}
          >
            {/* content=============================== */}
            <Box
              sx={{
                mr: 3,
                flexGrow: 1,
                mt: 5,
                "&:-webkit-scrollbar": { display: "none" },
              }}
            >
              <>
                <Box sx={{ py: 3, "&:-webkit-scrollbar": { display: "none" } }}>
                  <Box
                    sx={{
                      borderBottom: "1px solid",
                      borderColor: "divider",
                      mb: 3,
                      "&:-webkit-scrollbar": { display: "none" },
                    }}
                  >
                    <Typography fontSize={24} fontWeight={700}>
                      {foreigner ? "Email address" : t("input-mobile")}
                    </Typography>
                    <Typography
                      fontSize={18}
                      fontWeight={400}
                      color="grey.middle"
                      mb={2}
                    >
                      {foreigner
                        ? "Please enter your email address."
                        : t("input-mobile-guide")}
                    </Typography>
                  </Box>

                  {foreigner ? (
                    <EmailInput
                      selected={selectedEmail}
                      value={emailInput}
                      emailValue={emailTypeInput}
                      sx={{ mb: 0.5 }}
                      onClick={(type) => setFocused(type)}
                      onSelect={(e) =>
                        setValue("emailType", "") &
                        setSelectedEmail(e.target.value)
                      }
                      // onEmailTypeChange={() => onEmailTypeChange()}
                    />
                  ) : (
                    <CommonInput
                      // onChange={onChangeInput}
                      onClick={() => setFocused("phone")}
                      value={input}
                    />
                  )}
                </Box>

                {!foreigner && (
                  <Box sx={{ display: "flex", justifyContent: "end" }}>
                    <Button
                      variant="outlined"
                      color="secondary"
                      sx={{ fontSize: 22, px: 3 }}
                      onClick={() => setForeigner(!foreigner) & setInput("")}
                    >
                      foreigner
                    </Button>
                  </Box>
                )}
              </>
            </Box>
          </Box>
        </Container>

        <Box
          py={3}
          sx={{
            ".hg-button": {
              minHeight: foreigner ? "70px" : "120px",
              fontSize: foreigner ? "1rem" : "1.5rem",
            },
          }}
        >
          <Keyboard
            keyboardRef={(el) => (keyboard.current = el)}
            layoutName={layout}
            layout={{ ...keyboardLayout }}
            // theme="hg-theme-default hg-layout-numeric numeric-theme"
            onChange={onChange}
            onKeyPress={onKeyPress}
          />
        </Box>

        {/* bottom =============================================== */}
        <Box>
          <Stack
            direction="row"
            justifyContent="space-between"
            sx={{ bgcolor: "grey.soft", p: 3 }}
          >
            <Typography fontSize={20} fontWeight={700}>
              {foreigner ? "Total payment amount" : t("total-pay")}
            </Typography>
            {/*  */}
            <Typography fontSize={20} fontWeight={700} color="primary.main">
              {foreigner
                ? `₩ ${Number(total).toLocaleString()}`
                : t("price", { price: Number(total).toLocaleString() })}
            </Typography>
          </Stack>
          <BottomButtons
            left={{
              label: t("prev"),
              onClick: () =>
                navigate("/order", {
                  replace: true,
                  state: { history: false },
                }),
            }}
            right={{
              label: t("next"),
              onClick: () => setOpen("confirm"),
              disabled:
                !total ||
                (foreigner
                  ? !/^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/.test(
                      emailContent()
                    )
                  : !input.length),
            }}
          />
        </Box>

        {/* 확인 modal====================================================== */}
        <ConfirmMobile
          {...{ foreigner }}
          open={open === "confirm"}
          onClose={() => setOpen(null)}
          content={foreigner ? emailContent() : input}
          onSubmit={onSubmit}
          onCancel={() =>
            setInput("") & keyboard.current.clearInput() & setOpen(null)
          }
        />
        {/* 확인 modal====================================================== */}
      </Container>
    </Layout>
  );
}
