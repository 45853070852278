//ui
import {
  FormControlLabel,
  FormControl,
  Typography,
  Box,
  Radio,
  RadioGroup,
  Stack,
} from "@mui/material";

// validation
import { useForm, Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";

// ----------------------------------------------------------------------

export default function LabelOption({
  value,
  items,
  onCategoryChange,
  onChange,
}) {
  const { t } = useTranslation();
  const { control } = useForm({
    mode: "onChange",
  });

  return (
    <>
      <RadioGroup value={value?.category}>
        {items.map((item, i) => (
          <FormControlLabel
            key={i}
            value={item.id}
            control={<Radio onChange={() => onCategoryChange(item.id, i)} />}
            sx={{
              width: "100%",
              alignItems: "start",
              ".MuiButtonBase-root:hover": {
                backgroundColor: "transparent",
              },
              ">.MuiButtonBase-root": {
                padding: "0 8px",
              },
              ".MuiFormControlLabel-label": {
                display: "block",
                width: "100%",
              },
            }}
            label={
              <Box
                sx={{
                  width: "100%",
                  pb: 2,
                }}
              >
                <Typography
                  sx={{
                    width: "100%",
                    whiteSpace: "nowrap",
                    fontSize: 20,
                    fontWeight: 700,
                    mb: 1,
                  }}
                >
                  {item.name}
                </Typography>
                {value?.category === item.id && (
                  <FormControl sx={{ minWidth: "300px" }}>
                    {/* sx={{ width: "100%" }} */}
                    <RadioGroup
                      defaultValue={
                        item.options && item.options.length > 0
                          ? item.options[0].id
                          : "-"
                      }
                      value={value?.id || ""}
                      sx={{ display: "flex" }}
                    >
                      {item.options.map((option) => (
                        <FormControlLabel
                          key={option.id}
                          value={option.id}
                          control={
                            <Radio
                              disabled={option.soldOut}
                              onChange={() => onChange(option)}
                            />
                          }
                          label={
                            <Stack
                              direction="row"
                              justifyContent="space-between"
                            >
                              <Typography fontSize={18} mr={3}>
                                {option.name}
                                {option.soldOut ? ` <품절>` : ""}
                              </Typography>
                              <Typography fontSize={18}>
                                +{option.price.toLocaleString()}원
                                {/* {t("price", {
                                    price: option.price.toLocaleString(),
                                  })} */}
                              </Typography>
                            </Stack>
                          }
                        />
                      ))}
                    </RadioGroup>
                  </FormControl>
                )}
              </Box>
            }
          />
        ))}
      </RadioGroup>
    </>
  );
}
