import { Button, Container, Grid, Typography } from "@mui/material";

export default function PayStep1({ onClickPay, onClickSimplePay }) {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography variant="h4">결제 방법을 선택해주세요.</Typography>
      </Grid>
      <Grid item xs={12} sm={12}>
        <Button
          onClick={onClickPay}
          sx={{ py: 10 }}
          variant="outlined"
          color="inherit"
          fullWidth
          style={{ height: "100%" }}
        >
          <div>
            <Typography variant="h2">카드결제</Typography>
            <Typography variant="body1">국내/해외</Typography>
          </div>
        </Button>
      </Grid>
      {/* <Grid item xs={12} sm={6}>
           <Button onClick={onClickSimplePay} sx={{py:10}} variant="outlined" color="inherit" fullWidth style={{height: '100%'}}>
               <div>
                   <Typography variant="h2">간편결제</Typography>
                   <Typography variant="body1">네이버페이 / Alipay / WechatPay</Typography>
               </div>
           </Button>
        </Grid> */}
    </Grid>
  );
}
