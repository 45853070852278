import {Box, Button, Container, Grid, Typography} from "@mui/material";
import {SvgIconStyle} from "components";
import SymbolIcon from "assets/icons/symbol.svg";
import {useTranslation} from "react-i18next";

export default function PayStep21({}) {
    const {t} = useTranslation();
    return <Box sx={{px: 4}}>
        <Box sx={{px: 10, mt: 8}}>
            <Box
                sx={{
                    paddingBottom: "60%",
                    backgroundColor: "grey.600",
                    position: "relative",
                }}
            >
                <SvgIconStyle
                    src={SymbolIcon}
                    origin
                    sx={{
                        width: "200px",
                        height: "150px",
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        transform: "translate(-50%,-50%)",
                        opacity: 0.4,
                    }}
                />
            </Box>
            <Typography variant="h2" fontSize={20} textAlign="center" mt={3}>
                {/* 결제를 위해 카드를 삽입하거나
      <br /> 삼성페이/애플페이/QR코드를 이용해주세요. */}
                {t("pay-methods")}
            </Typography>
        </Box>
    </Box>
}