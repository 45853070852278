export function addOrRemove(arr, prev, key) {
  return arr.includes(key)
    ? [...prev.filter((item) => item !== key)]
    : [...prev, key];
}

export const reloadSession = () => {
  const event = new Event("visibilitychange");
  document.dispatchEvent(event);
};

export function cookieToObject(cookies) {
  const cookieItems = cookies.split(";");

  const obj = {};
  cookieItems.forEach((item) => {
    // '='으로 분리
    const elem = item.split("=");
    // 키 가져오기
    const key = elem[0].trim();
    // 값 가져오기
    const val = decodeURIComponent(elem[1]);
    // 저장
    obj[key] = val;
  });
  return obj;
}

export function getCookieId(cookie) {
  const cookieObj = cookieToObject(cookie);
  const key = Object.keys(cookieObj).find((k) =>
    k.split(".").includes("Kiosk_ID")
  );
  return cookieObj[key];
}

export function getItemType(data) {
  switch (data?.mode) {
    // 일반상품-----------------
    case "NORMAL":
      switch (data?.normal?.optionMode) {
        case "NOT":
          return "NORMAL_SINGLE";
        case "OPTION":
          return "NORMAL_OPTION";
        case "TOP":
          return "NORMAL_CATEGORY";
        default:
          return "NORMAL";
      }

    // 시간상품-----------------
    case "TIME":
      switch (data?.time?.type) {
        case "MAIN":
          return "TIME_SINGLE";
        case "TYPE":
          return "TIME_OPTION";
        default:
          return "TIME";
      }
  }
}
