import { useState, useEffect } from "react";
import Cart from "layout/cart";
import { Container, Typography, Box, Stack, Button } from "@mui/material";
import {
  CommonCounter,
  CommonRadio,
  CategoryOption,
  CommonModal,
} from "components";
import {
  ItemCard,
  GoListButton,
  AddCartButton,
  FastestButton,
  Loading,
} from "components/unitComponent";
import SelectTime from "components/SelectTime";

import Api from "utils/wApi/index";
import useCart from "../store";
import { fDate } from "utils/formatTime";
import { getItemType } from "utils/common";

// translations
import { useTranslation } from "react-i18next";

import { useNavigate, useParams } from "react-router-dom";

// 장바구니 담기 함수
import {
  addNormal,
  addNormalOptions,
  addTime,
  addTimeOption,
} from "utils/addCart";

export default function Detail() {
  const { cart, removeAllCart, removeCart, callCart } = useCart(
    (state) => state
  );
  const [selected, setSelected] = useState(null);
  const [selectedMode, setSelectedMode] = useState();
  const [selectedType, setSelectedType] = useState();

  const { t } = useTranslation();
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);

  const [fastest, setFastest] = useState(false); //빠른시간 구매
  const fastestMode = fastest && selectedMode === "TIME"; //빠른시간구매 모드 여부

  const [open, setOpen] = useState(null); //빠른시간 구매 안내 모달

  const [fastestData, setFastestData] = useState(null); //빠른시간구매 상품 데이터

  const [count, setCount] = useState(0); //구매수량

  const [time, setTime] = useState(null); //선택된 시간

  const [callList, setCallList] = useState(0); //선택된 시간

  // [categoryOption] :: 일반 카테고리 옵션(객체로 저장)
  const [categoryOption, setCategoryOption] = useState({
    category: null, // 상위 옵션 카테고리 id
    id: null, // 하위 옵션 id
    price: null, //하위 옵션 추가 가격
  });
  // [typeOption] :: 시간 타입 옵션(id 값으로 저장)
  const [typeOption, setTypeOption] = useState(null);
  // [normalOption] :: 일반 옵션(id 값으로 저장)
  const [normalOption, setNormalOption] = useState(null);

  const { id } = useParams();

  // functions =====================================================

  // 상품 데이터 확인
  async function init() {
    try {
      const {
        data: { data },
      } = await Api.getDetail(null, +id);
      setSelected(data);
      setSelectedMode(data.mode);
      setSelectedType(getItemType(data));
    } catch (error) {
      alert(error.message);
    }
  }

  // 장바구니 담기
  async function addCart() {
    switch (selectedType) {
      case "NORMAL_SINGLE":
        await addNormal({
          id: selected.id,
          buyCount: count,
          price: totalPrice(),
        });
        callCart();
        init();
        break;
      case "NORMAL_OPTION":
        await addNormalOptions({
          id: selected.id,
          buyCount: count,
          price: totalPrice(),
          optionId: normalOption,
          optionMode: selected?.normal.optionMode,
        });
        callCart();
        init();
        break;
      case "NORMAL_CATEGORY":
        await addNormalOptions({
          id: selected.id,
          buyCount: count,
          price: totalCatePrice(),
          optionId: categoryOption.id,
          optionMode: selected?.normal.optionMode,
        });
        callCart();
        init();
        break;
      case "TIME_SINGLE":
        await addTime({
          id: fastest ? fastestData.id : time.id,
          buyCount: count,
          price: selected.price * count,
        });
        callCart();
        // 재고 수량 업데이트때문에 시간 리스트 한번 더 호출
        setCallList((prev) => (prev += 1));
        break;
      case "TIME_OPTION":
        console.log(totalTypePrice());
        await addTimeOption({
          id: fastest ? fastestData.id : time.id,
          buyCount: count,
          price: totalTypePrice(),
          optionId: typeOption,
        });
        callCart();
        // 재고 수량 업데이트때문에 시간 리스트 한번 더 호출
        setCallList((prev) => (prev += 1));
        break;

      default:
        alert("담을 수 있는 상품이 없습니다.");
        break;
    }
  }

  // 상품 가장 빠른 시간 불러오기
  async function getFastest() {
    const typeMode = selected.time.type === "TYPE";
    const type = typeMode ? "fastestType" : "fastestSingle";
    try {
      const { data } = await Api[type](null, null, {
        params: {
          idMenu: selected.id,
          idMenuType: typeMode ? typeOption : undefined,
          qty: count,
        },
      });
      setFastestData(data);
      console.log("가장 빠른 시간:::", data);
    } catch (error) {
      alert(error.message);
    }
  }

  // 일반상품 (단일,옵션) 총 가격 계산하기
  const totalPrice = () => {
    const NORMAL_OPTION = selectedType === "NORMAL_OPTION";
    const optionPrice = NORMAL_OPTION
      ? selected?.normal?.options?.find((v) => v.id === normalOption).price
      : 0;
    return (selected?.price + optionPrice || 0) * count;
  };
  // 일반상품 카테옵션 총 가격 계산하기
  const totalCatePrice = () => {
    const category = selected?.normal?.topOptions.find(
      (v) => v.id === categoryOption.category
    );
    const options = category.options.find((v) => v.id === categoryOption.id);
    const price = options.price;

    return (selected?.price + price || 0) * count;
  };
  // 시간 상품 옵션 총 가격 계산
  const totalTypePrice = () => {
    const optionPrice = selected.time.menuTypes.find(
      (v) => v.id === typeOption
    ).price;
    return (selected.price + optionPrice) * count;
  };

  const getLimit = () => {
    if (selectedMode === "TIME" && fastest) {
      return !!selected?.salesLimitOnce
        ? Math.min(...[fastestData?.maxQty, selected?.salesLimitOnce])
        : fastestData?.maxQty;
    } else if (selectedMode === "TIME" && !fastest) {
      return selected?.salesLimitOnce
        ? Math.min(...[time?.maxQty, selected?.salesLimitOnce])
        : time?.maxQty;
    } else {
      return selected?.salesLimitOnce
        ? Math.min(...[selected?.qty, selected?.salesLimitOnce])
        : selected?.qty;
    }
  };

  // 시간 선택시 최대 수량 조정
  const setMaxCount = (maxCount) => {
    maxCount < count ? setCount(maxCount) : null;
  };

  useEffect(() => {
    init();
  }, []);

  useEffect(() => {
    if (!fastestMode) return;
    getFastest();
  }, [count, typeOption, fastest]);

  // 시간상품 옵션 최초 선택
  useEffect(() => {
    if (selectedType !== "TIME_OPTION") return;
    setTypeOption(selected?.time?.menuTypes[0]?.id);
  }, [selected]);

  // 일반상품 옵션 최초 선택
  useEffect(() => {
    if (selectedType !== "NORMAL_OPTION") return;
    setNormalOption(selected?.normal?.options[0]?.id);
  }, [selected]);

  // 일반상품 카테고리 옵션 최초 선택
  useEffect(() => {
    if (selectedType !== "NORMAL_CATEGORY") return;
    const firstCategory = selected?.normal?.topOptions[0];
    setCategoryOption({
      category: firstCategory.id,
      id: firstCategory.options[0].id,
      price: firstCategory.options[0].price,
    });
  }, [selected]);

  useEffect(() => {
    if (!selected) return;
    // check=====================
    const temp = {
      NORMAL_SINGLE: "일반상품 단일",
      NORMAL_OPTION: "일반상품 옵션",
      NORMAL_CATEGORY: "일반상품 카테고리 옵션",
      TIME_SINGLE: "시간상품단일",
      TIME_OPTION: "시간상품 옵션",
    };
    console.log("선택된 상품:::", selected);
    console.log("선택된 상품 타입:::", `${selectedType}-${temp[selectedType]}`);
    // check=====================
  }, [selected]);

  // =================================================================================
  return (
    <Cart noCategory>
      <Container title="kiosk" sx={{ height: "100%", mt: 3.5 }}>
        <Container sx={{ py: 3, height: "100%" }}>
          {loading ? (
            <Loading />
          ) : (
            <Container
              sx={{
                px: "0 !important",
                display: "inline-flex",
                flexDirection: "column",
                height: "100%",
              }}
            >
              <Box
                sx={{
                  borderBottom: "1px solid",
                  borderColor: "divider",
                  display: "flex",
                }}
              >
                {/* 선택한 상품 정보 */}
                <ItemCard
                  item={selected}
                  sx={{ maxWidth: 225, mb: 4.5 }}
                  onClick={() => {
                    console.log(selected);
                  }}
                />
                <Button
                  onClick={() => setOpen("detail")}
                  sx={{ fontSize: 20, ml: 2 }}
                >
                  &lt;상품 상세정보 보기&gt;
                </Button>
              </Box>

              <Box
                sx={{
                  flexGrow: 1,
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                }}
              >
                <Box>
                  {/* 일반상품 + 옵션 */}
                  {selectedType === "NORMAL_OPTION" && (
                    <Box sx={{ mt: 3 }}>
                      <Typography fontSize={20} fontWeight={700} mb={2}>
                        {/* 옵션을 선택해주세요 */}
                        {t("select-option")}
                      </Typography>

                      <CommonRadio
                        items={selected.normal.options}
                        value={normalOption}
                        onChange={(e) => setNormalOption(e)}
                        tail
                      />
                    </Box>
                  )}

                  {/* 일반상품 + 카테고리 옵션 */}
                  {selectedType === "NORMAL_CATEGORY" && (
                    <Box sx={{ mt: 3 }}>
                      <Typography fontSize={20} fontWeight={700} mb={2}>
                        {/* 옵션을 선택해주세요 */}
                        {t("select-option")}
                      </Typography>
                      <CategoryOption
                        items={selected.normal.topOptions}
                        value={categoryOption}
                        onCategoryChange={(categoryId, i) => {
                          const { id, price } =
                            selected.normal.topOptions[i].options[0];
                          return setCategoryOption({
                            category: categoryId || "",
                            id,
                            price,
                          });
                        }}
                        onChange={(option) => {
                          setCategoryOption((prev) => ({
                            ...prev,
                            id: option.id || "",
                            price: option.price,
                          }));
                        }}
                      />
                    </Box>
                  )}

                  {/* 시간 상품 - 옵션 */}
                  {selectedType === "TIME_OPTION" && (
                    <Box sx={{ mt: 3 }}>
                      <Typography fontSize={20} fontWeight={700} mb={2}>
                        {/* 옵션을 선택해주세요 */}
                        {t("select-option")}
                      </Typography>

                      <CommonRadio
                        items={selected.time.menuTypes}
                        value={typeOption}
                        onChange={(e) => setTypeOption(e)}
                        tail
                      />
                    </Box>
                  )}

                  {/*  시간 상품 - 시간 선택 */}
                  <Stack
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                    mt={4}
                  >
                    <Box>
                      <Typography fontSize={20} fontWeight={700}>
                        {selectedMode === "TIME"
                          ? fastestMode
                            ? t("select-count") //"상품 수량을 선택해 주세요."
                            : t("select-pickup") //"픽업 시간 및 상품 수량을 선택해 주세요."
                          : t("select-count")}
                      </Typography>
                      {selectedMode === "NORMAL" &&
                        !!selected?.salesLimitOnce && (
                          <Typography
                            fontSize={16}
                            fontWeight={500}
                            color="grey.middle"
                            mb={1}
                          >
                            {t("max", { max: selected?.salesLimitOnce })}
                            {/* 1인당 최대 {max}개까지 구매
                          가능합니다. */}
                          </Typography>
                        )}
                    </Box>
                    {/* 가장 빠른 시간 구매 버튼 */}
                    {/* {!fastestMode && selectedMode === "TIME" && (
                      <FastestButton onClick={() => setOpen("fastest")} />
                    )} */}
                  </Stack>

                  {/* 수량 선택 ------------------- */}
                  <Box
                    sx={{
                      display: selectedMode === "TIME" ? "block" : "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      mt: 3,
                    }}
                  >
                    <Box>
                      {selectedMode === "TIME" ? null : (
                        <Typography fontSize={20} fontWeight={700}>
                          {selected?.name}
                        </Typography>
                      )}
                      <Typography
                        mt={selectedMode === "TIME" ? 3.5 : 0}
                        fontSize={selectedMode === "TIME" ? 18 : 16}
                        fontWeight={500}
                        color="grey.middle"
                        mb={1}
                      >
                        {selectedMode === "TIME"
                          ? t("select-count") //"상품 수량을 선택하세요."
                          : ""}
                        {/* `잔여 수량 ${selected?.qty}개` */}
                        {/* t("stock", { count: selected?.qty }) */}
                      </Typography>
                    </Box>

                    <CommonCounter
                      count={count}
                      setCount={setCount}
                      item={selected}
                      max={getLimit()}
                    />
                  </Box>

                  {/* 가장빠른 시간 */}
                  {fastestMode && (
                    <Box sx={{ mt: 5 }}>
                      <Typography fontSize={24}>
                        {/* 가장 빠른 시간  */}
                        {t("fastest-time")}
                        &nbsp;: &nbsp;
                        <Typography
                          component="strong"
                          fontWeight={700}
                          fontSize={32}
                        >
                          {fDate(fastestData?.time, "HH:mm")}
                        </Typography>
                      </Typography>
                    </Box>
                  )}

                  {/* 시간 상품 시간 선택------------------ */}
                  <Box sx={{ mt: 5 }}>
                    {!fastestMode && selectedMode === "TIME" && (
                      <SelectTime
                        {...{ selected, time, setTime, callList, setMaxCount }}
                      />
                    )}
                  </Box>
                </Box>

                {/* 장바구니 담기 버튼 */}
                <AddCartButton
                  onClick={() => addCart()}
                  disabled={!count}
                  t={t}
                />

                {/* 목록보기 버튼 */}
                <GoListButton onClick={() => navigate("/menus")} t={t} />
              </Box>
            </Container>
          )}
        </Container>

        {/*  */}
        <CommonModal
          open={open === "fastest"}
          onClose={() => {}}
          footer={{
            label: t("fastest-buy"),
            sx: { fontWeight: 700, letterSpacing: -1 },
            onClick: () => setFastest(true) & setOpen(null),
          }}
          subFooter={{ label: t("close"), onClick: () => setOpen(null) }}
          sx={{ minWidth: 400 }}
        >
          <Typography fontSize={24} fontWeight={700} mb={1} textAlign="center">
            {t("notice")}
          </Typography>
          <Typography
            fontSize={20}
            color="grey.middle"
            textAlign="center"
            letterSpacing={-0.7}
          >
            {t("fastest-notice")}
          </Typography>
        </CommonModal>

        <CommonModal
          open={open === "detail"}
          width="90vw"
          onClose={() => {}}
          footer={{
            label: t("close"),
            sx: { fontWeight: 700, letterSpacing: -1 },
            onClick: () => setOpen(null),
          }}
          // subFooter={{ label: t("close"), onClick: () => setOpen(null) }}
          sx={{ minWidth: 400 }}
        >
          <Box sx={{ maxHeight: "75vh", overflowY: "scroll" }}>
            <Box
              dangerouslySetInnerHTML={{
                __html: selected?.information,
              }}
            />
          </Box>
        </CommonModal>
      </Container>
    </Cart>
  );
}
