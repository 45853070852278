import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

// @mui
import { Stack, Button, AppBar, Typography, Container } from "@mui/material";

// hooks
import useOffSetTop from "../../hooks/useOffSetTop";

// components
import { Logo, SvgIconStyle, CommonModal } from "components";
//
import { ToolbarStyle, ToolbarShadowStyle } from "./HeaderToolbarStyle";
import HomeIcon from "assets/icons/home-active.svg";
import { fDate } from "utils/formatTime";
import { useTranslation } from "react-i18next";
import useCountdown from "hooks/useCountdown";
import useCart from "store";
import Cookie from "js-cookie";

// ----------------------------------------------------------------------

export default function Header({ transparent }) {
  const HEADER_DESKTOP_HEIGHT = 96;
  const isScrolling = useOffSetTop(HEADER_DESKTOP_HEIGHT);
  const navigate = useNavigate();
  const [open, setOpen] = useState(null);
  const [time, setTime] = useState(new Date());
  const { t } = useTranslation();
  // const [date, setDate] = useState(new Date());
  const { countdown: date } = useCart((state) => state);
  const { countdown, extendCountdown } = useCountdown(date);

  useEffect(() => {
    const timeCount = setInterval(() => {
      setTime(new Date());
    }, 1000);
    return () => clearInterval(timeCount);
  }, []);

  // 5분 타이머
  // useEffect(() => {
  //   if (
  //     (countdown?.minutes === "00") &
  //     (countdown?.seconds === "30") &
  //     (Cookie.get("qz-extended") !== "extended")
  //   )
  //     setOpen("info");

  //   const timeOver = setTimeout(() => {
  //     if ((countdown?.minutes == "00") & (countdown?.seconds == "01")) {
  //       alert("구매제한 시간이 종료되었습니다") & navigate("/");
  //     }
  //   }, 1000);
  //   return () => clearTimeout(timeOver);
  // }, [countdown]);

  return (
    <AppBar
      sx={{
        boxShadow: 0,
        bgcolor: "transparent",
        minHeight: "95px",
        maxHeight: "95px",
        position: "relative",
      }}
    >
      <ToolbarStyle
        disableGutters
        transparent={transparent}
        // scrolling={isScrolling}
        sx={{
          px: 2,
          height: "unset !important",
          minHeight: "unset !important",
        }}
      >
        <Container
          maxWidth={false}
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            py: 3,
          }}
        >
          <Logo />

          {/* 5분 타이머 */}
          {/* {!(countdown?.minutes == "00" && countdown?.seconds == "00") && (
            <Typography
              // color="primary.main"
              fontWeight={700}
              fontSize={24}
              sx={{ mr: 2 }}
            >
              {`${countdown?.minutes}:${countdown?.seconds}`}
            </Typography>
          )} */}

          <Stack direction="row" alignItems="end">
            <Typography
              color="primary.main"
              fontWeight={700}
              fontSize={24}
              sx={{ textDecoration: "underline", mr: 2 }}
            >
              {/* 현재시간 */}
              {t("time")} : {fDate(time, "HH:mm")}
            </Typography>
            <Button onClick={() => navigate("/")}>
              <SvgIconStyle src={HomeIcon} origin size={32} />
            </Button>
          </Stack>
        </Container>
      </ToolbarStyle>

      {isScrolling && <ToolbarShadowStyle />}

      {/* 30초 남았을 경우 2분 연장 안내 모달 */}
      {/* <CommonModal
        open={open === "info"}
        onClose={() => {}}
        footer={{
          label: "2분 연장",
          sx: { fontWeight: 700, letterSpacing: -1 },
          onClick: () => {
            const extendMinutes = 2;
            extendCountdown(extendMinutes);
            setOpen(null);
            Cookie.set("qx-extended", "extended");
            date.setMinutes(date.getMinutes() + extendMinutes);
          },
        }}
        subFooter={{ label: t("close"), onClick: () => setOpen(null) }}
        sx={{ minWidth: 400 }}
      >
        <Typography textAlign={"center"} fontSize={20} fontWeight={600} my={3}>
          구매 제한 시간이 30초 남았습니다<br></br> 연장하시겠습니까?
        </Typography>
      </CommonModal> */}
    </AppBar>
  );
}
