/* eslint-disable */
import "./App.css";
import React, { useEffect, useState } from "react";
import ThemeProvider from "theme";
import { BrowserRouter, Routes, Route } from "react-router-dom";

// pages
import Home from "pages/Home";
import Menu from "pages/Menu";
import Detail from "pages/Detail";
import Order from "pages/Order";
import Info from "pages/Info";
import Pay from "pages/Pay";
import Complete from "pages/Complete";

// notistack
import { SnackbarProvider } from "notistack";
import { StyledMaterialDesignContent } from "utils/attrs";

// slick-carousel
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

// translation
import { I18nextProvider } from "react-i18next";
import i18n from "./i18n";

import Cookie from "js-cookie";
import { CircularProgress, Container } from "@mui/material";

function App() {
  const [id, setId] = useState(Cookie.get("Kiosk_ID"));

  useEffect(() => {
    if (typeof window !== "undefined") {
      const queryString = window.location.search;
      const urlParams = new URLSearchParams(queryString);
      const queryValue = urlParams.get("code");
      if (process.env.NODE_ENV === "production") {
        // 운영
        if (!queryValue) {
          window.alert("KIOSK CODE가 없습니다!");
          Cookie.remove("Kiosk_ID");
          setId(null);
          // -----------------
          // console.log("test");
          // Cookie.set("Kiosk_ID", queryValue || "QS23-KIOSK-14");
          // localStorage.setItem("Kiosk_ID", queryValue || "QS23-KIOSK-14");
          // setId(queryValue || "QS23-KIOSK-14");
          // return;
        } else {
          Cookie.set("Kiosk_ID", queryValue);
          localStorage.setItem("Kiosk_ID", queryValue);
          setId(queryValue);
        }
      } else {
        // 개발
        Cookie.set("Kiosk_ID", queryValue || "QS23-KIOSK-14");
        localStorage.setItem("Kiosk_ID", queryValue || "QS23-KIOSK-14");
        setId(queryValue || "QS23-KIOSK-14");
      }
    }
  }, [window]);

  return (
    <I18nextProvider i18n={i18n}>
      <>
        {id ? (
          <BrowserRouter>
            <SnackbarProvider
              autoHideDuration={2000}
              maxSnack={1}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "center",
              }}
              Components={{
                default: StyledMaterialDesignContent,
                success: StyledMaterialDesignContent,
                error: StyledMaterialDesignContent,
                warning: StyledMaterialDesignContent,
              }}
            >
              <ThemeProvider>
                <Routes>
                  <Route path="/" element={<Home />} />
                  <Route path="/menus" element={<Menu />} />
                  <Route path="/detail/:id" element={<Detail />} />
                  <Route path="/order" element={<Order />} />
                  <Route path="/info" element={<Info />} />
                  <Route path="/pay" element={<Pay />} />
                  <Route path="/complete" element={<Complete />} />
                </Routes>
              </ThemeProvider>
            </SnackbarProvider>
          </BrowserRouter>
        ) : (
          <Container sx={{ py: 5, display: "flex", justifyContent: "center" }}>
            <CircularProgress />
          </Container>
        )}
      </>
    </I18nextProvider>
  );
}

export default App;
