// import { reloadSession, signOutApp } from "utils/common";

// ===========================================================================

import axios from "axios";

// ----------------------------------------------------------------------

export const basePath = process.env.REACT_APP_HOST_API;

const axiosInstance = axios.create({
  baseURL: basePath,
  timeout: 60 * 1000, // Timeout
  withCredentials: true, // Check cross-site Access-Control
});

// const isServer = typeof window === "undefined";

axiosInstance.interceptors.request.use(
  async function (config) {
    return config;
  },
  async function (error) {
    console.error("request-error", error);
    return Promise.reject(error) & error;
  }
);

axiosInstance.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    // 토큰이 없을 경우 혹은 만료됐을 때
    if (
      error.response?.status === 401 &&
      error.response.data.error === "invalid_token"
    ) {
      // signOutApp();
      // if (!isServer) {
      //   alert("로그인 페이지로 이동합니다.");
      //   return Router.replace("/account/login");
      // }
    }

    return error.response
      ? Promise.reject(error.response.data)
      : Promise.reject({ message: "error" });
  }
);

export default axiosInstance;
