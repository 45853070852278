import { useState, useEffect } from "react";

// components
import Cart from "layout/cart";
import {Grid, Container, Box, Typography} from "@mui/material";
import { ItemCard, ItemArrow, Loading } from "components/unitComponent";
import { useNavigate } from "react-router-dom";

// translations

import { useTranslation } from "react-i18next";

import Slider from "react-slick";
import Api from "utils/wApi/index";
import useCart from "../store";
import AdMenu from "../components/ad/ad-menu";

// ----------------------------------------------------------------------

export default function Items() {
  const { t } = useTranslation();
  const [items, setItems] = useState([]);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const { active, cart } = useCart((state) => state);

  const showCount = 6;

  const page = [
    ...Array.from({ length: Math.ceil(items.length / showCount) }, (v, i) => i + 1),
  ];

  const itemSliderSetting = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <ItemArrow />,
    prevArrow: <ItemArrow prev />,
  };

  async function init() {
    setLoading(true);
    try {
      const {
        data: { data },
      } = await Api.getItems(null, null, {
        params: { size: 100, idBranchMenu: active },
      });
      setItems(data.content);
      console.log("상품 리스트", data.content);
    } catch (error) {
      alert(error.message);
    }
    setLoading(false);
  }
  useEffect(() => {
    if (!active) return navigate("/");
    init();
  }, [active]);

  return (
    <Cart>

        <Container
          sx={{
            width: "100%",
            flexGrow: 1,
            display: "flex",
            flexDirection: "column",
            mb: 12,
            "padding-left": '0px !important',
            "padding-right": '0px !important',
            "&:-webkit-scrollbar": { display: "none" },
          }}
        >
          {loading ? (
            <Loading sx={{ height: "500px", width: "100%" }} />
          ) : (
            <Box
              sx={{
                "&:-webkit-scrollbar": { display: "none" },
                py: 3,
                position: "relative",
                flexGrow: 1,
                ".slick-dots": { bottom: "-60px" },
                "li.slick-active": {
                  button: {
                    "::before": { color: "primary.main" },
                    "::after": { width: "30px" },
                  },
                },
                px: 0,
              }}
            >
              <Slider {...itemSliderSetting}>
                {page.map((page, i) => (
                  <Grid
                    container
                    rowSpacing={1.5}
                    key={i}
                    sx={{ display: "flex !important", px: 0, }}
                  >
                    {items
                      .slice(i * showCount, i * showCount + showCount)
                      .map((item, idx) => (
                        <Grid item xs={4} key={idx} >
                          <ItemCard
                            item={item}
                            onClick={() => {
                              navigate(`/detail/${item.id}`);
                            }}
                          />
                        </Grid>
                      ))}
                    <Box>
                      <AdMenu />
                    </Box>
                  </Grid>
                ))}
              </Slider>
            </Box>
          )}
        </Container>
    </Cart>
  );
}
